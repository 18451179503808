import { createTheme,responsiveFontSizes } from "@mui/material/styles";



let theme = createTheme({
  palette: {
    primary: {
      main: "#23376c",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "none",
    },
    button: {
      textTransform: "none",
    }
  },
  
});


theme = responsiveFontSizes(theme);

export {theme};