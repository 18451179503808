import {
	Alert,
	Box,
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Snackbar,
	Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { styles } from "./form.styles";
import { Form, Field } from "react-final-form";
import axios from "axios";
import SuccessPage from "../../pages/SuccessPage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { classicNameResolver } from "typescript";

export interface IUserValues {
	[key: string]: any;
}

export interface UserFormData {
	RegID: string;
	Fname: string;
	Mname?: string;
	Lname: string;
	CourceID: string;
	ContactNo: number;
	AlternateNo: number;
	State: string;
	City: string;
	Pincode: number;
	Address: string;
	PaymentMode: string;
	PaymentStatus: string;
	EmailID: string;
	Fees: number;
}

export interface IUser {
	[key: string]: IUserValues;
}

export interface ISelect {
	[key: string]: string;
}

function FormFields() {
	let classes = styles();
	const [courseDetails, setCourseDetails] = useState(null);
	const [items, setItems] = useState("");
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const paymentOptions = [
		// { value: "online", label: "Online" },
		{ value: "offline", label: "Offline" },
	];

	const navigatePayment = () => {
		navigate("/payment");
		setUserIDLocal();
	};

	function submitFormHandler(formData: UserFormData) {
		const formArray: UserFormData[] = [];
		formArray.push(formData);
		console.log("formData", formArray);
		setOpen(true);
		var data = JSON.stringify({ Data: [{ ...formData }] });
		console.log("data", data);
		var config = {
			method: "post",
			url: "https://thakurcapital.com/Courses/API/Update/Registrations",
			headers: {
				Authorization: "Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				let data: IUser = response.data;
				localStorage.setItem("UserID", data["Data"][0].RegID);
			})
			.catch(function (error) {});

		if (formArray[0].PaymentMode == "offline") {
			navigate("/success");
		} else if (formArray[0].PaymentMode == "online") {
			navigatePayment();
		}
	}

	useEffect(() => {
		let course = JSON.parse(localStorage.getItem("course") || "");
		if (course) {
			setItems(course.courseID);
		}
	}, []);

	function setUserIDLocal() {}

	const onSubmit = async (values: any) => {
		console.log("values", values);
		values = {
			...values,
			PaymentMode: values.PaymentMode ? values.PaymentMode["value"] : null,
		};
		const formData: UserFormData = {
			RegID: "New",
			Fname: values.Fname,
			Mname: "",
			Lname: values.Lname,
			CourceID: items,
			ContactNo: values.ContactNo,
			AlternateNo: values.AlternateNo,
			State: values.State,
			City: values.City,
			Pincode: values.Pincode,
			Address: values.Address,
			EmailID: values.EmailID,
			PaymentMode: values.PaymentMode,
			PaymentStatus: "Not paid",
			Fees: 500,
		};
		submitFormHandler(formData);
	};

	const required = (value: any) => (value ? undefined : "Required");
	const mustBeNumber = (value: number) =>
		isNaN(value) ? "Must be a number" : undefined;
	const minValue = (min: number) => (value: number) =>
		isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
	const composeValidators =
		(...validators: any[]) =>
		(value: any) =>
			validators.reduce(
				(error, validator) => error || validator(value),
				undefined
			);

	return (
		<Box className={classes.formWrapper}>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit}>
						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								marginBottom: "18px",
							}}
							className={classes.formDivider}
						>
							<Field name="Fname" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="First Name"
											size="small"
											sx={{
												width: "100%",
											}}
											className={classes.inputField}
											{...input}
											error={meta.error && meta.touched ? true : false}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
							<Field name="Lname" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Last Name"
											size="small"
											sx={{
												maxWidth: "345px",
												width: "100%",
											}}
											className={classes.inputField}
											{...input}
											error={meta.error && meta.touched ? true : false}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								marginBottom: "18px",
							}}
							className={classes.formDivider}
						>
							<Field name="EmailID" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Email"
											size="small"
											sx={{
												width: "100%",
											}}
											className={classes.inputField}
											{...input}
											error={meta.error && meta.touched ? true : false}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
							<Field name="ContactNo" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Phone Number"
											size="small"
											sx={{
												maxWidth: "345px",
												width: "100%",
											}}
											className={classes.inputField}
											error={meta.error && meta.touched ? true : false}
											{...input}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
						</Stack>

						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								marginBottom: "18px",
							}}
							className={classes.formDivider}
						>
							<Field name="AlternateNo" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Contact Number"
											size="small"
											sx={{
												maxWidth: "345px",
												width: "100%",
											}}
											className={classes.inputField}
											error={meta.error && meta.touched ? true : false}
											{...input}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
							<Field name="Address" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Address"
											size="small"
											sx={{
												width: "100%",
											}}
											className={classes.inputField}
											error={meta.error && meta.touched ? true : false}
											{...input}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
						</Stack>

						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								marginBottom: "18px",
							}}
							className={classes.formDivider}
						>
							<Field name="City" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="City"
											size="small"
											sx={{
												maxWidth: "345px",
												width: "100%",
											}}
											className={classes.inputField}
											error={meta.error && meta.touched ? true : false}
											{...input}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
							<Field name="State" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="State"
											size="small"
											sx={{
												maxWidth: "345px",
												width: "100%",
											}}
											className={classes.inputField}
											{...input}
											error={meta.error && meta.touched ? true : false}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
						</Stack>

						<Stack
							direction="row"
							justifyContent="space-between"
							sx={{
								marginBottom: "18px",
							}}
							className={classes.formDivider}
						>
							<Field
								name="Pincode"
								validate={composeValidators(required, mustBeNumber)}
							>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<TextField
											variant="outlined"
											label="Pincode"
											size="small"
											sx={{
												width: "100%",
											}}
											className={classes.inputField}
											error={meta.error && meta.touched ? true : false}
											{...input}
											helperText={
												meta.error && meta.touched ? meta.error : false
											}
										/>
									</Box>
								)}
							</Field>
							<Field name="PaymentMode" validate={required}>
								{({ input, meta }) => (
									<Box component="div" className={classes.inputFieldWrapper}>
										<Select
											options={paymentOptions}
											placeholder="Select Payment Method"
											{...input}
											required={true}
											className={
												meta.error && meta.touched ? "errorBorder" : "noError"
											}
										/>
									</Box>
								)}
							</Field>
						</Stack>
						<Button
							sx={{ width: "100%" }}
							variant="contained"
							type="submit"
							disabled={submitting}
						>
							Submit
						</Button>
					</form>
				)}
			/>
		</Box>
	);
}

export default FormFields;
