import { Box } from "@mui/material";
import ThakurCapitalWealthBuilder from "../components/courses/ThakurCapitalWealthBuilder";
import Header from "../components/header/header";

function Level0() {
	return (
		<Box component="div">
			<Header></Header>
			<ThakurCapitalWealthBuilder></ThakurCapitalWealthBuilder>
		</Box>
	);
}

export default Level0;
