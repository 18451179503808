import { makeStyles } from "@mui/styles";

export const styles = makeStyles(() => ({

  wrapper : {
    paddingBottom : '10px',
  },
	card: {
		padding: "20px 0",
    textAlign : "center",
    backgroundColor:"#23376c",
	},
  leftpannel : {
    width: "60%",
  },
  rightpannel : {
    width: "40%",
    marginLeft : "20px"
  },
  eventIcons : {
    marginRight : '4px',
  },
  img : {
    width:"100%",
  },
	flex: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
  courseType : {
    color : "#fff",
    fontSize: '25px !important',
    lineHeight: '1.3',
    textAlign:"center",
  },
  courseTitle : {
    color : "#ffcb08",
    fontSize: '30px !important',
    lineHeight: '1.3',
    fontWeight : '700 !important',
    textAlign:"center",
  },
  seminarHighLight : {
    color : "#000",
    fontSize: '18px !important',
    lineHeight: '1.3',
    fontWeight : '700 !important',
    paddingBottom : '5px'
  },
  dividing : {
    minHeight:'20px',
    backgroundColor : '#ffcb08',  
    marginBottom : '20px',
  },
  divider :  {
    marginBottom : '10px',
  },
  courseDetails : {
    paddingTop : '10px',
  },
  headline : {
    color : "#000",
    fontSize: '17px !important',
    lineHeight: '1.3 !important',
    paddingBottom : '5px',
    fontWeight : '700 !important',
  },
  icon : {
    width: '10px !important',
    height: '10px !important',
    marginRight: '8px',
  },
  list : {
    padding : '0 10px !important'
  },
  listText : {
    fontSize: '14px !important',
    lineHeight: '1.3',
    margin : '0 !important'
  },
  listElement : {
    padding : '0 !important',
  },
  subheadline : {
    color : "#000",
    fontSize: '14px !important',
    lineHeight: '1.3',
    margin : '0 !important'
  },
  upcomingcard : {
    padding : '10px',
    marginBottom:'10px'
  },
  coursesType : {
    fontSize: '14px !important',
    lineHeight: '1.3',
    margin : '10px 0 0 !important ',
    color:"#fff",
    textAlign : "center"
  },
  CourseDesc : {
    padding : "10px 0",
  },
  coursesDescTitle : {
    fontSize: '14px !important',
    lineHeight: '1.3',
    margin : '0 !important',
    fontWeight : '700 !important',
  },
  CourseSubtitle : {
    padding: "5px 0",
    
  },
  coursesDescTitleContent : {
    fontSize: '14px !important',
    lineHeight: '1.3',
    color: '#797979',
    fontWeight: '200 !important',
    marginTop : '5px !important',
  },
  paperContainer : {
    padding:'10px 0 0',
    margin:"20px auto",
    maxWidth:"500px",
    width : '100%',
    backgroundColor:"#23376c !important",
  },
  paymentWrapper : {
    padding : "10px",
  },
  userName : {
    fontSize: '18px !important',
    textTransform: 'capitalize',
    fontWeight: '600 !important',
    lineHeight : "1.4 !important",
    color : "#fff",
    textAlign : "center",
  },
  subDetails : {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    marginTop: '9px',
    color : "#fff",
    textAlign : "center",
  },
  leftBox : {
    padding : "0 10px",
  }
}));
