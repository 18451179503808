import { Box, Button, Container, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styles } from "./payment.style";
import axios from "axios";
import { UserFormData } from "../components/registration/form";
import { useNavigate } from "react-router-dom";

export interface paymentDetails {
	fees: string;
	OnClick: (data: string) => void;
}

export interface Iparams {
	CALLBACK_URL: string;
	CHANNEL_ID: string;
	CHECKSUMHASH: string;
	CUST_ID: string;
	EMAIL: string;
	INDUSTRY_TYPE_ID: string;
	MID: string;
	MOBILE_NO: string;
	ORDER_ID: string;
	TXN_AMOUNT: string;
	WEBSITE: string;
}

export interface IInfo {
	action: string;
	params: Iparams;
}

function Payment() {
	const classes = styles();
	const [user, setUser] = useState<any>([{}]);
	const [course, setCourse] = useState<any>([{}]);
	const navigate = useNavigate();

	async function getCourseDetails(courseID: any) {
		var data = "";
		var config = {
			method: "get",
			url: `https://thakurcapital.com/Courses/API/ByID/Courses/${courseID}`,
			headers: {
				Authorization: "Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
			},
			data: data,
		};

		await axios(config)
			.then(function (response) {
				const data = response.data;
				setCourse(data["Data"]);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	async function getUser(UUID: any) {
		var data = "";
		var config = {
			method: "get",
			url: `https://thakurcapital.com/Courses/API/ByID/Registrations/${UUID}`,
			headers: {
				Authorization: "Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
			},
			data: data,
		};

		await axios(config)
			.then(function (response) {
				const data = response.data;
				setUser(data["Data"][0]);
				getCourseDetails(data["Data"][0].CourceID);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		let user = localStorage.getItem("UserID") || "";
		if (user) {
			getUser(user);
		}
	}, []);

	const navigateSuccess = () => {
		navigate("/success");
	};

	function paymentStatus(data: any) {
		console.log(data);
		if (data.STATUS === "TXN_SUCCESS") {
			const formData: UserFormData = {
				RegID: user.RegID,
				Fname: user.Fname,
				Mname: "",
				Lname: user.Lname,
				CourceID: user.CourceID,
				ContactNo: user.ContactNo,
				AlternateNo: user.AlternateNo,
				State: user.State,
				City: user.City,
				Pincode: user.Pincode,
				Address: user.Address,
				EmailID: user.EmailID,
				PaymentMode: "Online",
				PaymentStatus: "Paid",
				Fees: user.Fees,
			};

			const formArray: UserFormData[] = [];
			formArray.push(formData);
			var dataValues = JSON.stringify({ Data: [{ ...formData }] });
			var config = {
				method: "post",
				url: "https://thakurcapital.com/Courses/API/Update/Registrations",
				headers: {
					Authorization:
						"Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
					"Content-Type": "application/json",
				},
				data: dataValues,
			};

			axios(config)
				.then(function (response) {
					let data = response.data;
					navigateSuccess();
					console.log("afterUpdate", data);
				})
				.catch(function (error) {});
		}
	}

	const paymentDetailsValues: paymentDetails = {
		fees: course[0].Fees,
		OnClick: paymentStatus,
	};

	function isDate(val: any) {
		// Cross realm comptatible
		return Object.prototype.toString.call(val) === "[object Date]";
	}

	function isObj(val: any) {
		return typeof val === "object";
	}

	function stringifyValue(val: any) {
		if (isObj(val) && !isDate(val)) {
			return JSON.stringify(val);
		} else {
			return val;
		}
	}

	function buildForm(parameter: IInfo) {
		const { action, params } = { ...parameter };
		const form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", action);

		Object.keys(params).forEach((key) => {
			const input = document.createElement("input");
			input.setAttribute("type", "hidden");
			input.setAttribute("name", key);
			input.setAttribute("value", stringifyValue(params[key as keyof Iparams]));
			form.appendChild(input);
		});

		return form;
	}

	function post(details: IInfo) {
		const form = buildForm(details);
		document.body.appendChild(form);
		form.submit();
		form.remove();
	}

	const getPaymentData = (data: any) => {
		return fetch(`http://localhost:5000/api/payment`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.catch((err) => console.log(err));
	};

	const makePayment = () => {
		getPaymentData({
			amount: Number(course[0].Fees),
			userID: user.RegID,
			email: user.EmailID,
			mobileNumber: user.ContactNo,
		}).then((response: Iparams) => {
			console.log("res", response);
			var information: IInfo = {
				action: "https://securegw-stage.paytm.in/order/process",
				params: response,
			};
			post(information);
		});
	};

	return (
		<Box component="div" className={classes.paymentWrapper}>
			<Container>
				<Paper
					elevation={3}
					className={classes.paperContainer}
					sx={{ width: "100%" }}
				>
					<Box component="div" className={classes.leftBox}>
						<Typography
							variant="h6"
							component="h6"
							className={classes.userName}
						>
							Hello {user ? user.Fname : "Xyz"} {user ? user.Lname : "ABC"}
						</Typography>
						<Typography
							variant="h6"
							component="h6"
							className={classes.subDetails}
						>
							Please check Your course Details
						</Typography>
						<Typography
							variant="h5"
							component="h5"
							className={classes.courseType}
						>
							{course[0].CourseType ? course[0].CourseType : ""}
						</Typography>
						<Typography
							variant="h5"
							component="h5"
							className={classes.courseTitle}
						>
							{course[0].CourseTitle ? course[0].CourseTitle : ""}
						</Typography>
						<Typography
							variant="h5"
							component="h5"
							className={classes.courseType}
						>
							{course[0].CourseSubTitle ? course[0].CourseSubTitle : ""}
						</Typography>
						<Typography variant="h5" className={classes.coursesType}>
							Date : {course[0].CourseDate ? course[0].CourseDate : ""}
						</Typography>
						<Typography variant="h5" className={classes.coursesType}>
							Language : {course[0].Language ? course[0].Language : ""}
						</Typography>
						<Typography variant="h5" className={classes.coursesType}>
							Time : {course[0].Time ? course[0].Time : ""}
						</Typography>
						<Typography variant="h5" className={classes.coursesType}>
							Location :{" "}
							{course[0].CourseLocation ? course[0].CourseLocation : ""}
						</Typography>
						<Typography variant="h5" className={classes.coursesType}>
							Fees : {course[0].Fees ? course[0].Fees : ""}
						</Typography>
					</Box>
					<Button
						sx={{ width: "100%", margin: "10px 0" }}
						variant="contained"
						type="submit"
						onClick={makePayment}
					>
						Pay Now
					</Button>
					{/* <PaytmButton {...paymentDetailsValues}></PaytmButton> */}
				</Paper>
			</Container>
		</Box>
	);
}

export default Payment;
