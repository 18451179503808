import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Container,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import React from "react";
import { styles } from "./successStyle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

function SuccessPage() {
	const classes = styles();
	const navigate = useNavigate();

	function navigateToHome() {
		navigate("/Level0");
	}

	return (
		<Box component="div">
			<Container>
				<Paper elevation={3} className={classes.paperContainer}>
					<Stack alignItems="center">
						<Box component="div" className={classes.successBox}>
							<CheckCircleIcon fontSize="large" className={classes.svgIcon} />
							<Typography variant="h6" className={classes.Title}>
								Success
							</Typography>
						</Box>
					</Stack>
					<Stack alignItems="center">
						<Box component="div">
							<Typography variant="h6" className={classes.blackColor}>
								Congratulation, your registration has been successfully done.
							</Typography>
						</Box>
					</Stack>
					<Button
						sx={{ width: "100%", margin: "10px 0" }}
						variant="contained"
						type="submit"
						onClick={navigateToHome}
					>
						Go TO HOME
					</Button>
				</Paper>
			</Container>
		</Box>
	);
}

export default SuccessPage;
