import React from "react";
import logo from "./logo.svg";
import "./style/reset.css";
import { Route, Routes } from "react-router-dom";
import { theme } from "./style/themes";
import { ThemeProvider } from "@mui/styles";
import RegistrationForm from "./components/registration/registration";
import Level0 from "./pages/Level0";
import Level1 from "./pages/Level1";
import Level2 from "./pages/Level2";
import Level3 from "./pages/Level3";
import SuccessPage from "./pages/SuccessPage";
import Payment from "./pages/payment";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Routes>
				<Route path="/Level0" element={<Level0 />}></Route>
				<Route path="/Level1" element={<Level1 />}></Route>
				<Route path="/Level2" element={<Level2 />}></Route>
				<Route path="/Level3" element={<Level3 />}></Route>
				<Route
					path="/registrationForm"
					element={<RegistrationForm></RegistrationForm>}
				></Route>
				<Route path="/payment" element={<Payment />}></Route>
				<Route path="/success" element={<SuccessPage />}></Route>
			</Routes>
		</ThemeProvider>
	);
}

export default App;
