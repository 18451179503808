import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { styles } from "./course.styles";
import { ICourse } from "./goodmorning";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import { useNavigate } from "react-router-dom";

function EventCardUI(props: ICourse) {
	const navigate = useNavigate();

	function payHandler(e: React.SyntheticEvent) {
		props.OnClick(e, props.CourseType, props.CourseID);
	}

	function payClick() {
		const courseDetails = {
			courseID: props.CourseID,
			courseType: props.CourseType,
		};
		localStorage.setItem("course", JSON.stringify(courseDetails));
		navigateHome();
	}

	const navigateHome = () => {
		navigate("/registrationForm");
	};

	const classes = styles();
	return (
		<Paper elevation={2} className={classes.upcomingcard}>
			<Stack direction="row" alignItems="center">
				<EventAvailableRoundedIcon
					color="primary"
					className={classes.eventIcons}
				/>
				<Typography variant="h5" className={classes.coursesType}>
					{props.CourseType ? props.CourseType : ""} -{" "}
					{props.CourseTitle ? props.CourseTitle : ""}
				</Typography>
			</Stack>
			<Stack
				direction="row"
				alignItems="end"
				className={classes.CourseSubtitle}
			>
				<DescriptionRoundedIcon
					color="primary"
					className={classes.eventIcons}
				/>
				<Typography variant="h5" className={classes.coursesType}>
					{props.CourseSubTitle ? props.CourseSubTitle : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<CalendarMonthRoundedIcon
					color="primary"
					className={classes.eventIcons}
				/>
				<Typography variant="h5" className={classes.coursesType}>
					{props.CourseDate ? props.CourseDate : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<PersonRoundedIcon color="primary" className={classes.eventIcons} />
				<Typography variant="h5" className={classes.coursesType}>
					{props.CourseBy ? props.CourseBy : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<LanguageRoundedIcon color="primary" className={classes.eventIcons} />
				<Typography variant="h5" className={classes.coursesType}>
					{props.Language ? props.Language : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<HourglassBottomRoundedIcon
					color="primary"
					className={classes.eventIcons}
				/>
				<Typography variant="h5" className={classes.coursesType}>
					{props.Time ? props.Time : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<LocationOnRoundedIcon color="primary" className={classes.eventIcons} />
				<Typography variant="h5" className={classes.coursesType}>
					{props.CourseLocation ? props.CourseLocation : ""}
				</Typography>
			</Stack>
			<Stack direction="row" alignItems="end">
				<CurrencyRupeeRoundedIcon
					color="primary"
					className={classes.eventIcons}
				/>
				<Typography variant="h5" className={classes.coursesType}>
					{props.Fees ? props.Fees : ""}
				</Typography>
			</Stack>
			<Stack className={classes.CourseDesc}>
				<Typography variant="h5" className={classes.coursesDescTitle}>
					Course Details :{" "}
					<Box component="span" className={classes.coursesDescTitleContent}>
						{props.CourseDesc ? props.CourseDesc : ""}
					</Box>
				</Typography>
			</Stack>
			<Button onClick={payClick} fullWidth variant="contained" size="medium">
				Register
			</Button>
		</Paper>
	);
}

export default EventCardUI;
