import { makeStyles } from  '@mui/styles';

export const styles = makeStyles((theme:any) => ({

  header : {
    padding: '10px 0',
  },
  menu : {
    maxWidth : "400px",
    width : "100%",
  },
  image: {
      maxWidth: '200px !important',
      width:'100% !important',
      minHeight : "60px !important",
  },
  flex : {
    display:"flex",
    alignItems : 'center',
    justifyContent : "space-between"
  },
  headerMenu : {
    display:"flex",
    alignItems : 'center',
    justifyContent : "space-between",
    width:'100%',
    [theme.breakpoints.down('md')]: {
      flexDirection : "column !important",
	},
  }
}));