import { Box, Container } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ICourse, ICourseData } from "../components/courses/goodmorning";
import StockMarketSuccess from "../components/courses/StockMarketSuccess";
import StockMarketSuccessDerivatives from "../components/courses/StockMarketSuccessDerivatives";
import Header from "../components/header/header";

function Level0() {
	return (
		<Box component="div">
			<Header></Header>
			<StockMarketSuccessDerivatives></StockMarketSuccessDerivatives>
		</Box>
	);
}

export default Level0;
