
import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme : any) => ({
	header: {
		padding: "10px 0",
	},
	menu: {
		maxWidth: "400px",
		width: "100%",
	},
	logo: {
		backgroundColor: "#e9ebf1",
		padding: "10px",
	},
	image: {
		maxWidth: "200px !important",
		width: "100% !important",
		minHeight: "100px !important",
		margin: "0 auto",
	},
	flex: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	divider: {
		marginBottom: "10px",
		borderWidth: 0,
		borderStyle: "none !important",
		borderColor: "unset !important",
		borderBottomWidth: "unset !important",
		padding: "1px",
		backgroundColor: "#c6c7ce",
	},
  registrationPage : {
    padding : "30px 10px",
    backgroundColor : "#23376c",
    minHeight : '100vh',
  },
  title : {
    color: '#000',
    fontSize: '22px !important',
    fontWeight: '700 !important',
    lineHeight: '1.3',
    textAlign : 'center',
    padding : '8px 0'
  },

	subTitle : {
    color: '#000',
    fontSize: '18px !important',
    fontWeight: '700 !important',
    lineHeight: '1.3',
    textAlign : 'center',
    padding : '0px 0'
  },

	formWrapper : {
		padding: "20px 10px",
		maxWidth: '720px',
		width: '100%',
		margin : '0 auto',
		boxSizing:"border-box",
	},
	formDivider : {
		[theme.breakpoints.down('md')]: {
      flexDirection : "column !important",
			marginBottom : "0 !important"
	},
	},
	formTitle : {
		fontWeight: '600',
		fontSize: '16px',
		lineHeight: '150%',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'right',
		letterSpacing: '0.005em',
		color: '#414141',
	},
	inputField : {
		maxWidth : '380px',
		width : "100%",
		background: '#fff',
		'&::placeholder': {
      fontStyle: 'italic',
    },
		[theme.breakpoints.down('md')]: {
			maxWidth : '100% !important',
	},
	},
	selectBox : {
		maxWidth: "315px",
		width: "100%",
	},
	inputFieldWrapper: {
		maxWidth : '345px',
		width : "100%",
		[theme.breakpoints.down('md')]: {
			maxWidth : '100%',
			marginBottom : "20px"
	},
	}
}));
