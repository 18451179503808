import { Avatar, Container, Link } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import Navbar from "../Nav/NavBar";
import { styles } from "./header.styles";

function Header() {
	const classes = styles();

	return (
		<header className={classes.header}>
			<Container>
				<Box component="div" className={classes.flex}>
					<Box component="div">
						<Box component="div" className="logo">
							<Avatar
								className={classes.image}
								src="https://thakurcapital.com/wp-content/uploads/2022/10/WhatsApp-Image-2022-11-21-at-14.42.26.png"
							/>
						</Box>
					</Box>
					<Box component="div" className={classes.menu}>
						<Navbar />
					</Box>
				</Box>
			</Container>
		</header>
	);
}

export default Header;
