import { makeStyles } from "@mui/styles";

export const styles = makeStyles(() => ({
  paperContainer : {
    maxWidth: '500px',
    margin: '100px auto 0',
  },
  successBox : {
    textAlign: 'center',
    backgroundColor: '#8bc34a',
    width: '100%',
    padding: '50px 0',
  },
  svgIcon: {
    width:'50px',
    height : '50px',
    color:"#fff",
  },
  Title : {
    color:"#fff",
    fontSize:"30px !important",
  },
  blackColor : {  
    color : '#969696',
    fontSize:"23px !important",
    textAlign: 'center',
    padding: '50px 20px',
    lineHeight: '116% !important',
    letterSpacing: '0 !important',
  }
}));
