import { Avatar, Box, Container, Divider, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { styles } from "./form.styles";
import { ICourse } from "../courses/goodmorning";
import FormFields from "./form";
import axios from "axios";
import Header from "../header/header";

function RegistrationForm() {
	const [Course, setCourse] = useState<any>();
	let classes = styles();

	async function getData(items: any) {
		var data = "";
		var config = {
			method: "get",
			url: `https://thakurcapital.com/Courses/API/ByID/Courses/${items}`,
			headers: {
				Authorization: "Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
			},
			data: data,
		};

		await axios(config)
			.then(function (response) {
				const data = response.data;
				setCourse(data["Data"]);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		let courseDetails = JSON.parse(localStorage.getItem("course") || "");
		if (courseDetails) {
			getData(courseDetails.courseID);
		}
	}, []);

	return (
		<Box component="div" className={classes.registrationPage}>
			{/* <Header></Header> */}
			<Container>
				<Paper
					elevation={3}
					sx={{
						maxWidth: "750px",
						width: "100%",
						margin: "0 auto",
					}}
				>
					<Stack>
						<Box component="div" className={classes.logo}>
							<Avatar
								className={classes.image}
								src="https://thakurcapital.com/wp-content/uploads/2022/10/WhatsApp-Image-2022-11-21-at-14.42.26.png"
							/>
						</Box>
						<Divider className={classes.divider} />
					</Stack>
					<Typography variant="h5" className={classes.title}>
						Register
					</Typography>
					<Divider />
					<FormFields></FormFields>
				</Paper>
			</Container>
		</Box>
	);
}

export default RegistrationForm;
