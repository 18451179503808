import {
	Avatar,
	Box,
	Container,
	Divider,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Stack,
	Typography,
	List,
	Button,
	Backdrop,
	CircularProgress,
} from "@mui/material";
import banner from "../../images/banner.jpg";
import { styles } from "./course.styles";
import CircleIcon from "@mui/icons-material/Circle";
import EventCardUI from "./eventCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCourse } from "../../services/getCourse";
import axios from "axios";

export interface ICourse {
	CourseID: string;
	CourseTitle: string;
	CourseSubTitle: string;
	CourseType: string;
	CourseDesc: string;
	CourseDate: string;
	CourseLocation: string;
	CourseBy: string;
	Language: string;
	Fees: string;
	Time: string;
	Count: string;
	OnClick: (data: any, courseType: string, courseID: string) => void;
}

export interface ICourseData {
	[key: string]: ICourse[];
}

function StockMarketSuccess(props: any) {
	const classes = styles();
	const [Course, setCourse] = useState<any>();
	const [isLoading, setLoading] = useState(true);

	function CourseRender() {
		return (
			<Stack className="top-section">
				<img src={banner} className={classes.img} />
				<Stack className={classes.card}>
					<Container>
						<Typography
							variant="h5"
							component="h5"
							className={classes.courseType}
						>
							{Course[0].CourseType ? Course[0].CourseType : ""}
						</Typography>
						<Typography
							variant="h5"
							component="h5"
							className={classes.courseTitle}
						>
							{Course[0].CourseTitle ? Course[0].CourseTitle : ""}
						</Typography>
					</Container>
				</Stack>
				<Box component="div" className={classes.dividing}></Box>
				<Stack direction="row">
					<Container className={classes.wrapper}>
						<Stack>
							<Typography variant="h5" className={classes.seminarHighLight}>
								{"Seminar Highlights"}
							</Typography>
							<Divider className={classes.divider} />
						</Stack>
						<Stack direction="row" className={classes.courseDetails}>
							<Stack className={classes.leftpannel}>
								<Typography variant="h6" className={classes.headline}>
									The seminar focuses on Becoming a Pro by learning
								</Typography>
								<ListElement></ListElement>
							</Stack>
							<Stack className={classes.rightpannel}>
								<Typography variant="h6" className={classes.headline}>
									Upcoming Seminar
								</Typography>

								{Course.map((item: ICourse) => {
									return (
										<EventCardUI {...item} key={item.CourseID}></EventCardUI>
									);
								})}
							</Stack>
						</Stack>
					</Container>
				</Stack>
			</Stack>
		);
	}

	async function getData() {
		var data = "";
		var config = {
			method: "get",
			url: `https://thakurcapital.com/Courses/API/ByType/Courses/Level 1`,
			headers: {
				Authorization: "Basic b25rYXJrdW1iaGFyNDJAZ21haWwuY29tOnN0YXJ0MTIzNA==",
			},
			data: data,
		};
		await axios(config)
			.then(function (response) {
				let data: ICourseData = response.data;
				setCourse(data["Data"]);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				setLoading(true);
			});
	}

	useEffect(() => {
		getData();
	}, []);

	function ListElement(props: any) {
		return (
			<Stack>
				<List className={classes.listElement}>
					<ListItem className={classes.list}>
						<CircleIcon className={classes.icon} />
						<ListItemText
							classes={{
								primary: classes.listText,
							}}
							primary="Finding Direction in market"
						/>
					</ListItem>
					<ListItem className={classes.list}>
						<CircleIcon className={classes.icon} />
						<ListItemText
							classes={{
								primary: classes.listText,
							}}
							primary="Understanding demand zone and supply zone (cash Market)"
						/>
					</ListItem>
					<ListItem className={classes.list}>
						<CircleIcon className={classes.icon} />
						<ListItemText
							classes={{
								primary: classes.listText,
							}}
							primary="RSI (Relative Strength Index) the powerful indicator"
						/>
					</ListItem>
					<ListItem className={classes.list}>
						<CircleIcon className={classes.icon} />
						<ListItemText
							classes={{
								primary: classes.listText,
							}}
							primary="Fruitful stocks (short term and long term)"
						/>
					</ListItem>
					<ListItem className={classes.list}>
						<CircleIcon className={classes.icon} />
						<ListItemText
							classes={{
								primary: classes.listText,
							}}
							primary="Hand holding in Live trades"
						/>
					</ListItem>
				</List>
			</Stack>
		);
	}

	return isLoading ? (
		<Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={isLoading}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	) : (
		<CourseRender></CourseRender>
	);
}

export default StockMarketSuccess;
