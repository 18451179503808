import React from 'react';
import styled from 'styled-components';
import { styles } from "../header/header.styles";
import { Link } from "@mui/material";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index:1;
  width : 100%;

  li {
    padding: 18px 10px;
  }

  @media (max-width: 899.95px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  const classes = styles();
  return (
    <Ul open={open}>
      <ul className={classes.headerMenu}>
							<li className="menu-links">
								<Link href="https://thakurcapital.com/" underline="hover">
									{"Home"}
								</Link>
							</li>
							<li className="menu-links">
								<Link
									href="https://thakurcapital.com/index.php/about-us/"
									underline="hover"
								>
									{"About"}
								</Link>
							</li>
							<li className="menu-links">
								<Link
									href="https://thakurcapital.com/index.php/basic-demat-account/"
									underline="hover"
								>
									{"Services"}
								</Link>
							</li>
							<li className="menu-links">
								<Link
									href="https://thakurcapital.com/index.php/daily-updates/"
									underline="hover"
								>
									{"Blog"}
								</Link>
							</li>
						</ul>
    </Ul>
  )
}

export default RightNav